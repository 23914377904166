import Vue from 'vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Notifications from 'vue-notification'
import axios from 'axios'
import VueConfirmDialog from 'vue-confirm-dialog'


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(vueCustomElement)
Vue.use(Notifications)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.config.productionTip = false

const vueConfirmDialogCss = `
.vc-overlay *, .vc-overlay :after, .vc-overlay :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    -webkit-touch-callout: none;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0
}

.vc-title {
    color: var(--title-color);
    font-weight: var(--font-weight-black);
    font-size: var(--font-size-m);
    margin-bottom: 5px
}

.vc-text, .vc-title {
    padding: 0 1rem;
    width: 100%;
    text-align: center;
    line-height: normal
}

.vc-text {
    color: var(--message-color);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-s)
}

.vc-overlay {
    background-color: var(--overlay-background-color);
    width: 100%;
    height: 100%;
    -webkit-transition: all .1s ease-in;
    transition: all .1s ease-in;
    left: 0;
    top: 0;
    z-index: 999999999999;
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: baseline;
    align-content: baseline
}

.vc-container {
    background-color: var(--base-background-color);
    border-radius: 1rem;
    width: 286px;
    height: auto;
    display: grid;
    grid-template-rows:1fr -webkit-max-content;
    grid-template-rows:1fr max-content;
    -webkit-box-shadow: var(--container-box-shadow);
    box-shadow: var(--container-box-shadow)
}

.vc-text-grid {
    padding: 1rem
}

.vc-btn-grid {
    width: 100%;
    display: grid;
    grid-template-columns:1fr 1fr;
    border-radius: 0 0 1rem 1rem;
    overflow: hidden
}

.vc-btn-grid.isMono {
    grid-template-columns:1fr
}

.vc-btn {
    border-radius: 0 0 1rem 0;
    color: var(--button-color);
    background-color: var(--button-background-color);
    border: 0;
    font-size: 1rem;
    border-top: 1px solid var(--button-border-color);
    cursor: pointer;
    font-weight: var(--font-weight-bold);
    outline: none;
    min-height: 50px
}

.vc-btn:hover {
    background-color: var(--button-background-color-hover)
}

.vc-btn:disabled {
    background-color: var(--button-background-color-disabled)
}

.vc-btn:active {
    -webkit-box-shadow: var(--button-box-shadow-active);
    box-shadow: var(--button-box-shadow-active)
}

.vc-btn.left {
    border-radius: 0;
    border-right: 1px solid var(--button-border-color)
}

.vc-input[type=password] {
    width: 100%;
    outline: none;
    border-radius: 8px;
    height: 35px;
    border: 0;
    margin: 5px 0;
    background-color: var(--input-background-color);
    padding: 0 .5rem;
    font-size: var(--font-size-m);
    -webkit-transition: .21s ease;
    transition: .21s ease
}

.vc-input[type=password]:focus, .vc-input[type=password]:hover {
    background-color: var(--input-background-color-hover)
}

.fade-enter-active, .fade-leave-active {
    -webkit-transition: opacity .21s;
    transition: opacity .21s
}

.fade-enter, .fade-leave-to {
    opacity: 0
}

.zoom-enter-active, .zoom-leave-active {
    -webkit-animation-duration: .21s;
    animation-duration: .21s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: zoom;
    animation-name: zoom
}

.zoom-leave-active {
    animation-direction: reverse
}

@-webkit-keyframes zoom {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }
    to {
        opacity: 1;
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}

@keyframes zoom {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1)
    }
    to {
        opacity: 1;
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}

.vue-notification-group {
  display: block;
  position: fixed;
  z-index: 5000;
}

.vue-notification-wrapper {
  display: block;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}

.notification-title {
  font-weight: 600;
}

.vue-notification-template {
  display: block;\t
  box-sizing: border-box;\t
  background: white;\t
  text-align: left;\t
}

.vue-notification {
  display: block;
  box-sizing: border-box;  
  text-align: left;
  font-size: 12px;
  padding: 10px;
  margin: 0 5px 5px;

  color: white;
  background: #44A4FC;
  border-left: 5px solid #187FE7;
}

.vue-notification.warn {
  background: #ffb648;
  border-left-color: #f48a06;
}

.vue-notification.error {
  background: #E54D42;
  border-left-color: #B82E24;
}

.vue-notification.success {
  background: #68CD86;
  border-left-color: #42A85F;
}

.vn-fade-enter-active, .vn-fade-leave-active, .vn-fade-move  {
  transition: all .5s;
}

.vn-fade-enter, .vn-fade-leave-to {
  opacity: 0;
}
`

//Use this in local
//Vue.customElement('stykite-widget', App, { props: [] })

//comment this in local
axios.get(`${process.env.VUE_APP_SELF_HOST}/css/app.css?v=${new Date().getTime()}`).then(async data => {
    Vue.customElement('stykite-widget', App, {
        shadow: true,
        shadowCss: `${data.data} ${vueConfirmDialogCss}`,
        props: []
    })
})
