<template>
  <div class="col mb-3 justify-content-center ov-plan-card"
       :class="getPlanCardClassName"
       :style="{'border-color': getBorderColor(),'color':getTextColor(),
       'background': getCardBackgroundColor(), 'border-radius': getCardRadius()}">
    <div class="pricing-content">
      <div class="pricing-grid">
        <div class="pricing-card">
          <!--<div class="pricing-image">
            <img class="rounded-circle img-fluid" src="https://dev.app.stykite.com/img/PlanImg2.ae8db4fb.svg"
                 alt="logo">
          </div>-->
          <h3 class="font-light ov-plan-name" :style="{'color':getTextColor()}"><b class="font-extrabold">{{
              item.name
            }}</b>
          </h3>
          <p class="ov-plan-description" :style="{'color':getTextColor()}"
             v-html="formatDescription(item.description)"></p>

          <!--<div class="text-center mt-2" v-if="!finalTotal">
            <h2 class="monthly">&nbsp;</h2>
          </div>-->

          <div class="text-center mt-2 ov-plan-amount">
            <h2 class="monthly">{{ currencySymbol }}<span>{{
                finalTotal
              }}</span><span class="ov-frequency-label">/{{ getPerLabel }}</span>
            </h2>
          </div>

          <div :class="getContainerClassName">
            <div class="animation1"></div>
            <b-button
                :style="{'background-color':getButtonColor(),  'color': getButtonTextColor(),
                'border-color': getBorderColor, 'border-radius': getButtonRadius()}"
                :disabled="showLoader || disableCurrentPlan() || isDummyPlan()" @click="initPayment"
                :class="getButtonClassName">
              <b-spinner v-if="showLoader" small label="Spinning"></b-spinner>
              <span>{{ getButtonLabel() }}</span>
            </b-button>
            <div class="animation2"></div>
          </div>

          <p class="ov-button-sublabel" :style="{'color':getTextColor()}" v-html="getButtonSubLabel()"></p>

          <div v-if="!workflowSettings.meter_view || workflowSettings.meter_view==='SLIDER'">
            <div class="text-center">
              <div v-for="(slider, index) in sliders" :key="'slider_'+index" class="mt-2">
                <div v-if="slider.mode === 'POSTPAID'">
                  <div v-if="planType === 'MONTHLY'">
                    <b-form-input v-model="slider.valueMonth" type="range" step="1.0" :min="slider.min"
                                  :max="slider.max"
                                  @click="callStair(slider.valueMonth, slider.range, planType, slider.pricing_model, index)">
                    </b-form-input>
                    <div class="mt-0"> {{ slider.valueMonth }} {{ slider.plan.label }} /
                      {{ getPerLabel }}
                    </div>
                  </div>
                  <div v-if="planType === 'YEARLY'">
                    <b-form-input v-model="slider.valueYear" type="range" step="1.0" :min="slider.min"
                                  :max="slider.max"
                                  @click="callStair(slider.valueYear, slider.range, planType, slider.pricing_model, index)">
                    </b-form-input>
                    <div class="mt-0"> {{ slider.valueYear }} {{ slider.plan.label }} /
                      {{ getPerLabel }}
                    </div>
                  </div>
                  <div v-if="planType === 'WEEKLY'">
                    <b-form-input v-model="slider.valueWeek" type="range" step="1.0" :min="slider.min"
                                  :max="slider.max"
                                  @click="callStair(slider.valueWeek, slider.range, planType, slider.pricing_model, index)">
                    </b-form-input>
                    <div class="mt-0"> {{ slider.valueWeek }} {{ slider.plan.label }} /
                      {{ getPerLabel }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!workflowSettings.meter_view || workflowSettings.meter_view==='SLIDER'">
            <div class="text-center">
              <div v-for="(slider, index) in sliders" :key="'slider_2_'+index" class="mt-2">
                <div v-if="slider.mode === 'PREPAID'">
                  <div v-if="planType === 'MONTHLY'">
                    <MinusPlus :min="slider.min" :index="index" :max="slider.max" :value="slider.valueMonth"
                               :label="slider.plan.label" @onChange="onMinusPlusChange" align="center"/>
                  </div>
                  <div v-if="planType === 'YEARLY'">
                    <MinusPlus :min="slider.min" :index="index" :max="slider.max" :value="slider.valueYear"
                               :label="slider.plan.label" @onChange="onMinusPlusChange" align="center"/>
                  </div>
                  <div v-if="planType === 'WEEKLY'">
                    <MinusPlus :min="slider.min" :index="index" :max="slider.max" :value="slider.valueWeek"
                               :label="slider.plan.label" @onChange="onMinusPlusChange" align="center"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pricing-feature"><h4>Included Features</h4>
            <div class="pricing-feature-list ov-plan-features" v-html="item.features"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import util from '@/util/usageUtil'
import MinusPlus from "@/components/MinusPlus/index.vue";
import {isFreePlan} from "@/util/planUtil";

export default {
  props: {
    item: Object,
    index: Number,
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    email: {
      type: String
    },
    currencySymbol: {
      type: String
    },
    token: {
      type: String
    },
    apiKey: {
      type: String
    },
    textColor: {
      type: String
    },
    cardRadius: {
      type: String
    },
    planType: String,
    customerDetails: Object,
    workflowSettings: Object,
    mode: {
      type: String
    },
    countryCode: {
      type: String
    },
    zipCode: {
      type: String
    },
    planPreviewColor: {
      type: String
    }
  },
  data() {
    return {
      showLoader: false,
      settings: {},
      sliders: [],
      finalTotal: 0
    }
  },
  components: {
    MinusPlus
  },
  computed: {
    getButtonClassName() {
      return 'btn btn-full btn-pink ov-plan-button ' + `ov-plan-button-${this.index}`
    },
    getContainerClassName() {
      return `ov-button-container ov-button-container-${this.index}`
    },
    getPlanCardClassName() {
      return `ov-plan-card-${this.index}`
    },
    getPerLabel() {
      if (this.planType === 'MONTHLY')
        return 'month'
      if (this.planType === 'YEARLY')
        return 'year'
      if (this.planType === 'WEEKLY')
        return 'week'
      return ''
    }
  },
  watch: {
    planType: function () {
      //this.finalTotalCalculator()
    }
  },
  methods: {
    onMinusPlusChange(data) {
      const index = data.index
      const val = parseInt(data.value);
      if (this.planType === 'MONTHLY') {
        this.sliders[index].valueMonth = val
      }
      if (this.planType === 'YEARLY') {
        this.sliders[index].valueYear = val
      }
      if (this.planType === 'WEEKLY') {
        this.sliders[index].valueWeek = val
      }
      const slider = this.sliders[index]
      this.callStair(val, slider.range, this.planType, slider.pricing_model, index)
    },
    callStair(qty, range, type, pricing_model, index) {
      switch (pricing_model) {
        case 'STAIR':
          if (type === 'MONTHLY') {
            this.sliders[index].monthTotal = util.calculateStairCasePrice(Number(qty), range, type)
          }
          if (type === 'YEARLY') {
            this.sliders[index].yearTotal = util.calculateStairCasePrice(Number(qty), range, type)
          }
          if (type === 'WEEKLY') {
            this.sliders[index].weekTotal = util.calculateStairCasePrice(Number(qty), range, type)
          }
          break
        case 'VOLUME':
          if (type === 'MONTHLY') {
            this.sliders[index].monthTotal = util.calculateVolumnPrice(Number(qty), range, type)
          }
          if (type === 'YEARLY') {
            this.sliders[index].yearTotal = util.calculateVolumnPrice(Number(qty), range, type)
          }
          if (type === 'WEEKLY') {
            this.sliders[index].weekTotal = util.calculateVolumnPrice(Number(qty), range, type)
          }
          break
        case 'TIERED':
          if (type === 'MONTHLY') {
            this.sliders[index].monthTotal = util.calculateTieredPrice(Number(qty), range, type)
          }
          if (type === 'YEARLY') {
            this.sliders[index].yearTotal = util.calculateTieredPrice(Number(qty), range, type)
          }
          if (type === 'WEEKLY') {
            this.sliders[index].weekTotal = util.calculateTieredPrice(Number(qty), range, type)
          }
          break
      }
      this.finalTotalCalculator()
    },
    async initPayment() {
      if (this.isContactUsPlan()) {
        if (!this.workflowSettings.contact_us_url) {
          console.error('Contact Us url is not configured')
          alert('Contact Us url is not configured')
          return
        }
        window.location = this.workflowSettings.contact_us_url
        return
      }

      if (!this.customerIdentifier) {
        if (this.workflowSettings.redirect_url_not_login) {
          window.location = this.workflowSettings.redirect_url_not_login
        } else {
          this.$notify({group: 'plan_preview', type: 'error', title: 'Error', text: 'Redirect url is not configured'});
          console.error('Redirect url is not configured')
          alert('Redirect url is not configured')
        }
        return
      }

      // check if user has active subscription then just show yes no
      if (this.customerDetails && this.customerDetails.plan &&
          this.customerDetails.subscription_start_date <= new Date().getTime() &&
          this.customerDetails.subscription_end_date >= new Date().getTime() &&
          isFreePlan(this.item, this.planType)
      ) {
        this.$confirm(
            {
              message: 'Are you sure you want to switch your plan? The change will take effect immediately.',
              button: {
                no: 'No',
                yes: 'Yes'
              },
              callback: confirm => {
                if (confirm) {
                  this.actualPayment();
                }
              }
            }
        )
      } else {
        await this.actualPayment();
      }
    },
    async actualPayment() {
      // check if metered prepaid plan
      const prepaidUsage = []
      for (let i = 0; i < this.sliders.length; i++) {
        const slider = this.sliders[i]
        if (slider.plan.mode === 'PREPAID') {
          if (this.planType === 'MONTHLY') {
            prepaidUsage.push({meter_id: slider.plan.tsid, qty: parseInt(slider.valueMonth)})
          }
          if (this.planType === 'YEARLY') {
            prepaidUsage.push({meter_id: slider.plan.tsid, qty: parseInt(slider.valueYear)})
          }
          if (this.planType === 'WEEKLY') {
            prepaidUsage.push({meter_id: slider.plan.tsid, qty: parseInt(slider.valueWeek)})
          }
        }
      }

      this.showLoader = true
      const headers = {
        'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
      }
      const payload = {
        company_id: this.companyId,
        country_id: this.countryId,
        customer_identifier: this.customerIdentifier,
        email: this.email,
        plan_id: this.item.tsid,
        plan_type: this.planType,
        payment_component: 'PLAN',
        country_code: this.countryCode,
        zip_code: this.zipCode,
        usage: prepaidUsage
      }
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/payment`, payload, {headers: headers})
        if (data.status) {
          const val = data.value
          const mode = val.subscription_mode
          switch (mode) {
            case 'DIRECT':
              if (this.workflowSettings.redirect_url_after_payment) {
                window.location = this.workflowSettings.redirect_url_after_payment
                window.location.reload(true)
              } else {
                console.log('Redirect url is not configured')
                alert('Redirect url is not configured')
              }
              break
            case 'PAYMENT':
              if (val.gateway === 'STRIPE' || !val.gateway)
                window.location = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/${val.transaction_tsid}`
              if (val.gateway === 'PAYPAL')
                window.location = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/p/${val.transaction_tsid}`
              if (val.gateway === 'RAZORPAY')
                window.location = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/r/${val.transaction_tsid}`
              break
          }
        }
      } catch (e) {
        const data = e.response.data
        const message = data?.error?.message || 'Something went wrong. Please try again'
        this.$notify({group: 'plan_preview', type: 'error', title: 'Error', text: message});
        //alert(message);
      } finally {
        this.showLoader = false
      }
    },
    getButtonSubLabel() {
      if (this.disableCurrentPlan()) return '&nbsp;'
      if (this.getTrialDays()) return `Try for ${this.getTrialDays()} days`
      return '&nbsp;'
    },
    getButtonLabel() {
      if (this.disableCurrentPlan()) {
        return 'Currently on this plan'
      } else if (this.isContactUsPlan()) {
        return 'Contact Us'
      } else if (this.isDummyPlan()) {
        return 'Coming Soon'
      } else if (this.customerIdentifier) {
        return this.isFreePlanLocal() ? 'Free' : 'Purchase'
      }
      return this.isFreePlanLocal() ? 'Get Started Free' : 'Get Started'
    },
    disableCurrentPlan() {
      return (this.customerDetails && this.customerDetails.plan_type === this.planType &&
          this.customerDetails.plan && this.item.plan_tsid === this.customerDetails.plan.plan_tsid
          && this.customerDetails.subscription_start_date <= new Date().getTime() &&
          this.customerDetails.subscription_end_date >= new Date().getTime())
    },
    isDummyPlan() {
      return this.item.is_dummy_plan
    },
    isFreePlanLocal() {
      return isFreePlan(this.item, this.planType)
    },
    isContactUsPlan() {
      return this.item.is_contact_us_plan
    },
    onlyMeteredPlan() {
      let onlyMeter = true
      const price = this.item.price
      for (let i = 0; price && i < price.length; i++) {
        const item = price[i]
        if (item.pricing_model === 'FIXED') {
          onlyMeter = false
          break
        }
      }
      return onlyMeter;
    },
    getTrialDays() {
      const price = this.item.price
      for (let i = 0; i < price.length; i++) {
        const item = price[i]
        if (item.pricing_model === 'FIXED') {
          if (this.planType === 'MONTHLY' && item.frequency.month) {
            const actualPrice = item.frequency.month[0]
            return actualPrice.trial
          } else if (this.planType === 'YEARLY' && item.frequency.year) {
            const actualPrice = item.frequency.year[0]
            return actualPrice.trial
          } else if (this.planType === 'WEEKLY' && item.frequency.week) {
            const actualPrice = item.frequency.week[0]
            return actualPrice.trial
          }
        }
      }
      return 0
    },
    getRangeLabel(item, planType) {
      const min = planType === 'MONTHLY' ? item.month_min_range : item.year_min_range
      const max = planType === 'MONTHLY' ? item.month_max_range : item.year_max_range
      if (min && max) {
        return `${min}-${max}`
      }
      if (min && !max) {
        return `>${min}`
      }
      if (!min && max) {
        return `<${max}`
      }
    },
    getSliders() {
      for (let i = 0; i < this.item.price.length; i++) {
        const item = this.item.price[i]
        if (item.pricing_model === 'FIXED') {
          continue;
        }
        let freq = this.planType === 'MONTHLY' ? 'month' : ''
        if (!freq)
          freq = this.planType === 'YEARLY' ? 'year' : 'week'
        const min = item.frequency && item.frequency[freq] && item.frequency[freq].length && item.frequency[freq][0].min ? item.frequency[freq][0].min : 0;
        const max = item.frequency && item.frequency[freq] && item.frequency[freq].length && item.frequency[freq][item.frequency[freq].length - 1].max
            ? item.frequency[freq][item.frequency[freq].length - 1].max : 500000;
        const mode = item.mode
        this.sliders.push({
          mode: mode,
          plan: item,
          range: item.frequency[freq],
          pricing_model: item.pricing_model,
          min: min,
          max: max,
          valueMonth: mode === 'PREPAID' && min === 1 ? 1 : 0,
          valueYear: mode === 'PREPAID' && min === 1 ? 1 : 0,
          valueWeek: mode === 'PREPAID' && min === 1 ? 1 : 0,
          monthTotal: 0,
          yearTotal: 0,
          weekTotal: 0,
        })
        if (mode === 'PREPAID')
          this.onMinusPlusChange({index: this.sliders.length - 1, value: 1});
      }
    },
    finalTotalCalculator() {
      this.finalTotal = 0
      for (let i = 0; i < this.item.price.length; i++) {
        const item = this.item.price[i]
        const pricing_model = item.pricing_model;
        if (pricing_model === 'FIXED') {
          const frequency = item.frequency
          if (this.planType === 'MONTHLY') {
            this.finalTotal += frequency.month[0].price
          }
          if (this.planType === 'YEARLY') {
            this.finalTotal += frequency.year[0].price
          }
          if (this.planType === 'WEEKLY') {
            this.finalTotal += frequency.week[0].price
          }
        }
      }

      for (let i = 0; i < this.sliders.length; i++) {
        const slider = this.sliders[i]
        const monthPrice = slider.monthTotal
        const yearPrice = slider.yearTotal
        const weekPrice = slider.weekTotal
        if (this.planType === 'MONTHLY') {
          this.finalTotal += monthPrice
        }
        if (this.planType === 'YEARLY') {
          this.finalTotal += yearPrice
        }
        if (this.planType === 'WEEKLY') {
          this.finalTotal += weekPrice
        }
      }

      if (this.finalTotal)
        this.finalTotal = Number(this.finalTotal.toFixed(2))
    },
    getBorderColor() {
      const preview = this.getColorOverrideForPlan()
      if (this.mode === 'DARK') {
        return (preview?.color_settings.dark_mode.border_color) || this.item.color_settings?.dark_mode?.border_color
      }
      return (preview?.color_settings.border_color) || this.item.color_settings.border_color
    },
    getButtonColor() {
      const preview = this.getColorOverrideForPlan()
      if (this.mode === 'DARK') {
        return (preview?.color_settings.dark_mode.button_color) || this.item.color_settings?.dark_mode?.button_color
      }
      return (preview?.color_settings.button_color) || this.item.color_settings.button_color
    },
    getButtonTextColor() {
      const preview = this.getColorOverrideForPlan()
      if (this.mode === 'DARK') {
        return (preview?.color_settings.dark_mode.button_text_color) || this.item.color_settings?.dark_mode?.button_text_color
      }
      return (preview?.color_settings.button_text_color) || this.item.color_settings.button_text_color
    },
    getCardBackgroundColor() {
      const preview = this.getColorOverrideForPlan()
      if (this.mode === 'DARK') {
        return (preview?.color_settings.dark_mode.background_color) || this.item.color_settings?.dark_mode?.background_color
      }
      return (preview?.color_settings.background_color) || this.item.color_settings.background_color
    },
    getTextColor() {
      const preview = this.getColorOverrideForPlan()
      if (this.mode === 'DARK') {
        return (preview?.color_settings.dark_mode.text_color) || this.item.color_settings?.dark_mode?.text_color || this.textColor
      }
      return (preview?.color_settings.text_color) || this.item.color_settings.text_color || this.textColor
    },
    getButtonRadius() {
      const preview = this.getColorOverrideForPlan()
      if (this.mode === 'DARK') {
        return (preview?.color_settings.dark_mode.button_radius) || this.item.color_settings?.dark_mode?.button_radius
      }
      return (preview?.color_settings.button_radius) || this.item.color_settings.button_radius
    },
    getCardRadius() {
      return this.cardRadius
    },
    formatDescription(text) {
      if (text) {
        text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
      }
      return text
    },
    getColorOverrideForPlan() {
      if (this.planPreviewColor) {
        const previewColors = JSON.parse(this.planPreviewColor)
        for (let i = 0; i < previewColors.length; i++) {
          const preview = previewColors[i]
          if (this.item.tsid === preview.plan_tsid) {
            return preview
          }
        }
      }
      return null
    }
  },
  mounted() {
    this.getSliders()
    this.finalTotalCalculator()
  }
}
</script>

<style scoped>
.col {
  flex: 1; /* additionally, equal width */
  padding: 1em;
  margin: 1rem;
  border: solid;
  border-width: 2px;
}

.table_border {
  border-radius: 16px;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
  -moz-osx-font-smoothings: grayscale;
  -webkit-font-smoothing: antialiased;
  width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}


.pricing-content {
  margin: 25px 0 0;
}

.pricing-grid {
  display: grid;
  grid-gap: 24px;
  margin: 24px 0 0;
}

.pricing-card {
  position: relative;
  padding: 2px;
  border-radius: 16px;
}

.pricing-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 88px;
  height: 36px;
  margin: 0 auto;
  border-radius: 8px;
}


.pricing-card > h2 {
  margin: 24px 0 0;
  font-weight: 400 !important;
  font-size: 42px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
}

.pricing-card > h2 span {
  font-weight: 700 !important;
}

.pricing-card > h3 {
  margin: 24px 0 0;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.pricing-card > p {
  margin: 12px 0 0;
  padding: 0 12px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #BABEC7;
}

.pricing-card > p {
  color: #FFFFFF;
}

.pricing-card > p span {
  font-weight: 700 !important;
}

.pricing-card > .btn {
  margin: 32px 0 0;
}

.pricing-feature {
  margin: 8px 0 0;
  padding: 24px;
  border-radius: 8px;
}

.pricing-feature > h4 {
  margin: 0;
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 21px;
  /*color: #FFFFFF;*/
}

.pricing-feature > h4 + .pricing-feature-list {
  margin: 24px 0 0;
}

.pricing-feature-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pricing-feature-list > img {
  width: 14px;
  margin: 6px 0 0;
}

.pricing-feature-list > p {
  width: calc(100% - 14px);
  margin: 0;
  padding: 0 0 0 12px;
  color: #FFFFFF;
  font-size: 14px;
}

.pricing-feature-list + .pricing-feature-list {
  margin: 12px 0 0;
}


.btn {
  cursor: pointer;
  display: inline-block;
  font-weight: 600 !important;
  text-align: center;
  border: 0;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.btn:hover, .btn:focus, .btn:active {
  border: 0;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-full {
  width: 100%;
}

.btn-pink {
  padding: 10px 20px;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  /*border: 2px solid #F50369;*/
  border-radius: 8px;
}

.doller {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15.6px;
  line-height: 23px;
}

.per_price {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  padding-top: 20px;
}

.free_button {
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
}

.free_button:hover {
  background: transparent;
  border: 2px solid #FFFFFF;
}

.border_row {
  margin-top: 30px;
  border: 1px solid rgba(246, 246, 246, 0.24);
}

.summary {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.head_summary_detail {
  display: list-item;
  margin-left: 1em;
}

.text_card_label {
  width: 65%;
  height: 30px;
  text-wrap: wrap;
  margin-bottom: 5px;
}

.text_card_text {
  width: 30%;
  height: 30px;
  text-wrap: wrap;
  margin-bottom: 5px;
}
</style>
