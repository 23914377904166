<template>
  <div class="container invoice-wrapper">
    <div class="invoice-top">
      <div class="border_filed">
        <div class="col-12 mt-5">
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-12 font_display">
                  <h4 class="mb-0">Receipt</h4>
                  <h6 v-if="data.invoiceStatus === 'PAID'" class="px-2 mb-0" style="color: green">{{
                      data.invoiceStatus
                    }}</h6>
                  <h6 v-else-if="data.invoiceStatus === 'OVERDUE'" class="px-2 mb-0" style="color: red">
                    {{ data.invoiceStatus }}</h6>
                  <h6 v-else-if="data.invoiceStatus === 'PAYMENT REQUESTED'" class="px-2 mb-0" style="color: red">
                    Unpaid</h6>
                  <h6 v-else class="px-2 mb-0">{{ data.invoiceStatus }}</h6>
                </div>
                <div class="col-12" v-if="data.paymentLink">
                  <a class="font_size" :href="data.paymentLink">Click here to pay now</a>
                </div>
              </div>
            </div>
            <div class="col-6 text-right">
              <img v-if="data.merchantLogo" width="120px" :src="data.merchantLogo"/>
            </div>
          </div>
        </div>
        <div class="col-12 mt-5">
          <div class="row">
            <div class="col-6">
              <h6 class="font-weight-bold">Bill To</h6>
              <p class="p-0 m-0 font_size">{{ data.customerName }}</p>
              <p class="p-0 m-0 font_size">{{ data.customerAddress }}</p>
            </div>
            <div class="col-6 text-right ">
              <h6 class="font-weight-bold">Bill From</h6>
              <p class="p-0 m-0 font_size" v-html="data.companyAddress"></p>
            </div>
          </div>
        </div>
        <div class="col-12 mt-5">
          <div class="row">
            <div class="col-12"><h6 class="font-weight-bold">Your Order</h6>
              <p class="p-0 m-0 font_size">Invoice ID: {{ data.invoiceNumber }}</p>
              <p class="p-0 m-0 font_size">Payment Request Date: {{ data.invoiceDate }}</p>
              <div v-if="data.customFields">
                <div v-for="(item, index) in data.customFields" :key="index">
                  <p class="p-0 m-0 font_size">{{ item.label }}: {{ item.value }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-5">
          <table class="table table-bordered text-center">
            <thead>
            <tr>
              <th colspan="3" class="font_size font-weight-bold">Description</th>
              <th class="font_size font-weight-bold">Unit Price</th>
              <th class="font_size font-weight-bold">Quantity</th>
              <th class="font_size font-weight-bold">Price</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in data.items" :key="index">
              <td colspan="3" class="font_size font-weight-normal">{{ item.description }}</td>
              <td class="font_size font-weight-normal">{{ item.currencySymbol }}{{ item.price }}</td>
              <td class="font_size font-weight-normal">{{ item.qty }}</td>
              <td class="font_size font-weight-normal">{{ item.currencySymbol }}{{ item.totalPrice }}</td>
            </tr>
            <tr>
              <td colspan="3" class="font_size font-weight-bold "
                  style="border: 1px solid transparent !important;"></td>
              <td class="font_size font-weight-normal"
                  style="border: 1px solid transparent !important;"></td>
              <td class="font_size font-weight-normal"
                  style="border: 1px solid transparent !important;">Sub
                Total
              </td>
              <td class="font_size font-weight-normal"
                  style="border: 1px solid transparent !important;">
                {{ data.currencySymbol }} {{ data.billAmount }}
              </td>
            </tr>
            <tr>
              <td colspan="3" class="font_size font-weight-bold"
                  style="border: 1px solid transparent !important;"></td>
              <td class="font_size font-weight-normal" style="border: 1px solid transparent !important;"></td>
              <td class="font_size font-weight-normal" style="border: 1px solid transparent !important;">
                Sales Tax ({{ data.salesTaxPercent }}%)
              </td>
              <td class="font_size font-weight-normal" style="border: 1px solid transparent !important;">
                {{ data.currencySymbol }} {{ data.salesTax }}
              </td>
            </tr>
            <tr>
              <td colspan="3" class="font_size font-weight-bold"
                  style="border: 1px solid transparent !important;"></td>
              <td class="font_size font-weight-normal" style="border: 1px solid transparent !important;"></td>
              <td class="font_size font-weight-normal font-weight-bold"
                  style="border: 1px solid transparent !important;">
                Total
              </td>
              <td class="font_size font-weight-normal  font-weight-bold"
                  style="border: 1px solid transparent !important;">
                {{ data.currencySymbol }} {{ data.total }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 border-top py-3">
          <div class="row">
            <div class="col-12 font_size">
              <p class="mb-1">The amount {{ data.currencySymbol }} {{ data.total }} will appear on your bank statement
                as Stykite </p>
              <p v-if="data.merchantSupportEmail">Have any questions? Write to {{ data.merchantSupportEmail }}</p>
              <p style="font-size: smaller">You can also write to support@stykite.com for any billing related
                queries</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formData: {
      type: String
    },
  },
  data() {
    return {
      data: {}
    }
  },
  watch: {
    formData: function () {
      this.data = this.formData ? JSON.parse(this.formData) : {}
    }
  },
  mounted() {
    this.data = this.formData ? JSON.parse(this.formData) : {}
  }
}
</script>

<style>
body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

.invoice-wrapper {
  margin: 20px auto;
  max-width: 700px !important;
}

.border_filed {
  padding: 20px;
  border: 2px solid #000000c4;
}

.border-top {
  border-top: 2px solid #000000c4 !important;
}

.invoice-top {
  background: #ffffff;
  /*padding: 30px 30px 50px;*/
}

.font_display {
  display: flex;
}

.font_size {
  font-size: 16px;
}

.font_size_xl {
  font-size: 16px;
}

.borderless-cell {
  border: 1px solid transparent !important;
}

@page {
  margin: 0px !important;
  padding: 0px !important;
  size: 8.3in 11.7in !important;
}
</style>
