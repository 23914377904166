<template>
  <div>
    <b-overlay :show="showLoader" rounded="sm">
      <div v-if="!showLoader">
        <MyAccount v-if="widgetType==='MY_ACCOUNT'" :company-id="companyId" :country-id="countryId"
                   :customer-identifier="customerIdentifier" :token="token" :api-key="apiKey"
                   :mode="mode"/>
        <PlanPreview v-if="widgetType==='PLAN_PREVIEW' || !widgetType" :company-id="companyId" :country-id="countryId"
                     :status="status" :token="token" :api-key="apiKey"
                     :call-from="callFrom"
                     :customer-identifier="customerIdentifier" :email="email" :form-data="formData"
                     :background-color="backgroundColor" :switch-background-color="switchBackgroundColor"
                     :text-color="textColor" :card-radius="cardRadius"
                     :workflow-settings="workflowSettings" :mode="mode"
                     :country-code="countryCode"
                     :zip-code="zipCode" :plan-preview-color="planPreviewColor"/>
        <GroupPlanPreview v-if="widgetType==='PLAN_PREVIEW_GROUP'" :company-id="companyId" :country-id="countryId"
                          :customer-identifier="customerIdentifier" :api-key="apiKey" :status="status"
                          :country-code="countryCode"
                          :zip-code="zipCode"/>
        <AddOnList v-if="widgetType==='ADD_ON'" :company-id="companyId" :country-id="countryId"
                   :customer-identifier="customerIdentifier" :token="token" :api-key="apiKey"
                   :mode="mode"/>
        <SaveCard v-if="widgetType==='SAVE_CARD'" :company-id="companyId" :country-id="countryId"
                  :customer-identifier="customerIdentifier" :token="token" :api-key="apiKey" :label="label"
                  :button-css="buttonCss"/>
        <ButtonPlan v-if="widgetType==='PLAN_BUTTON'" :company-id="companyId" :country-id="countryId"
                    :api-key="apiKey" :customer-identifier="customerIdentifier" :label="label"
                    :plan-tsid="planTsid" :plan-type="frequency" :button-css="buttonCss"/>
        <InvoicePreview v-if="widgetType==='INVOICE_PREVIEW'" :form-data="formData"/>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import PlanPreview from '../components/PlanPreview'
import MyAccount from '../components/MyAccount'
import AddOnList from '../components/AddOnList'
import SaveCard from '../components/SaveCard'
import ButtonPlan from '../components/ButtonPlan'
import InvoicePreview from '../components/InvoicePreview'
import GroupPlanPreview from '../components/GroupPlanPreview'
import axios from "axios";

export default {
  props: {
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    countryISO2: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    email: {
      type: String
    },
    status: {
      type: String
    },
    token: {
      type: String
    },
    apiKey: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    switchBackgroundColor: {
      type: String
    },
    textColor: {
      type: String
    },
    cardRadius: {
      type: String
    },
    formData: {
      type: String
    },
    widgetType: {
      type: String
    },
    label: {
      type: String
    },
    callFrom: {
      type: String
    },
    workflowSettings: {
      type: String
    },
    mode: {
      type: String
    },
    countryCode: {
      type: String
    },
    zipCode: {
      type: String
    },
    planTsid: {
      type: String
    },
    frequency: {
      type: String
    },
    buttonCss: {
      type: String
    },
    planPreviewColor: {
      type: String
    }
  },
  data() {
    return {
      data: {},
      template: null,
      type: null,
      load: false,
      showLoader: true
    }
  },
  components: {
    PlanPreview,
    MyAccount,
    AddOnList,
    SaveCard,
    ButtonPlan,
    GroupPlanPreview,
    InvoicePreview
  },
  methods: {
    async resolveCountryFromIso() {
      try {
        if (this.countryId) {
          return
        }
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
        }
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/country/iso2/${this.countryISO2}`, {
          headers: headers
        })
        if (data && data.value) {
          const country = data.value.country
          const fallback = data.value.fallback
          if (country) {
            this.countryId = country.tsid
          }
          if (!country) {
            this.countryId = fallback.tsid
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.showLoader = false
      }
    }
  },
  async mounted() {
    await this.resolveCountryFromIso();
  }
}
</script>
