<template>
  <div id="app">
    <Navigation :company-id="companyId" :country-id="countryId" :country-i-s-o2="countryISO2" :status="status"
                :token="token" :api-key="apiKey"
                :customer-identifier="customerIdentifier" :email="email"
                :form-data="formData"
                :widget-type="widgetType"
                :call-from="callFrom"
                :label="label"
                :background-color="backgroundColor" :switch-background-color="switchBackgroundColor"
                :workflow-settings="workflowSettings"
                :text-color="textColor"
                :card-radius="cardRadius"
                :mode="mode"
                :country-code="countryCode"
                :zip-code="zipCode"
                :plan-tsid="planTsid"
                :frequency="frequency"
                :button-css="buttonCss"
                :plan-preview-color="planPreviewColor"
    />
  </div>
</template>

<style>

</style>

<script>

import Navigation from './components/navigation/Navigation'
import axios from "axios";

export default {
  props: {
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    countryISO2: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    email: {
      type: String
    },
    status: {
      type: String,
      default: 'ACTIVE',
    },
    token: {
      type: String,
    },
    apiKey: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    switchBackgroundColor: {
      type: String
    },
    textColor: {
      type: String
    },
    cardRadius: {
      type: String
    },
    formData: {
      type: String
    },
    widgetType: {
      type: String
    },
    label: {
      type: String
    },
    callFrom: {
      type: String
    },
    workflowSettings: {
      type: String
    },
    mode: {
      type: String
    },
    countryCode: {
      type: String
    },
    zipCode: {
      type: String
    },
    css: {
      type: String
    },
    planTsid: {
      type: String
    },
    frequency: {
      type: String
    },
    buttonCss: {
      type: String
    },
    planPreviewColor: {
      type: String
    }
  },
  data() {
    return {
      lightCss: '',
      darkCss: ''
    }
  },
  components: {
    Navigation
  },
  async mounted() {
    if (!this.status) {
      this.status = 'ACTIVE'
    }
    await this.getColorSettings();
    await this.appendCssUsingAPI();
  },
  watch: {
    css: function () {
      this.appendLocalCss()
    },
    mode: function () {
      this.appendCssUsingAPI()
    }
  },
  methods: {
    async getColorSettings() {
      try {
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/css`);
        this.lightCss = data?.value?.light || '';
        this.darkCss = data?.value?.dark || '';
      } catch (e) {
        console.log(e)
      }
    },
    async appendCssUsingAPI() {
      const widgets = document.getElementsByTagName('stykite-widget');
      for (let i = 0; i < widgets.length; i++) {
        const darkNode = this.createCSSNode(this.darkCss, `stykite-custom-api-css-${new Date().getTime()}`)
        const lightNode = this.createCSSNode(this.lightCss, `stykite-custom-api-css-${new Date().getTime()}`)
        const widget = widgets[i]
        this.removeNode(widget, 'stykite-custom-api-css-')
        if (this.mode === 'DARK') {
          if (widget && widget.shadowRoot) {
            widget.shadowRoot.appendChild(darkNode)
          }
        } else {
          if (widget && widget.shadowRoot) {
            widget.shadowRoot.appendChild(lightNode)
          }
        }
      }
      this.appendLocalCss();
    },
    appendLocalCss() {
      if (this.css) {
        const widgets = document.getElementsByTagName('stykite-widget');
        for (let i = 0; i < widgets.length; i++) {
          const cssNode = this.createCSSNode(this.css, `stykite-custom-css-${new Date().getTime()}`)
          const widget = widgets[i]
          this.removeNode(widget, 'stykite-custom-css-')
          if (widget && widget.shadowRoot) {
            widget.shadowRoot.appendChild(cssNode)
          }
        }
      }
    },
    createCSSNode(cssStyle, id) {
      let css = document.createElement('style');
      if (id)
        css.id = id
      css.innerHTML = cssStyle
      return css
    },
    removeNode(widget, id) {
      if (widget && widget.shadowRoot) {
        const customCss = widget.shadowRoot.querySelectorAll("[id^='" + id + "']")
        for (let i = 0; i < customCss.length; i++) {
          customCss[i].remove()
        }
      }
    }
  }
}
</script>
